/** @format */

import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import QuizIcon from "@mui/icons-material/Quiz";
import InfoIcon from "@mui/icons-material/Info";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Divider,
  IconButton,
} from "@mui/material";
// import { List, ListItem } from "@material-ui/core";

import ApprovalIcon from "@mui/icons-material/Approval";
import GroupsIcon from "@mui/icons-material/Groups";
import DialogActions from "@mui/material/DialogActions";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { Link as Lnk } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { validateAndPost } from "./graphql/mutations";
import { Oval } from "react-loader-spinner";
import { defaultTheme } from "./App";
import ReCAPTCHA from "react-google-recaptcha";
import { terms, privacy } from "./strings.js";
import imageSource from "./logos.png";

import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  CardActions,
  CardActionArea,
} from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://freethrow.ai/">
        FreeThrow LLC.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const style1 = {
  height: 15,
};

const handleYTClick = () => {
  window.open(
    "https://www.youtube.com/channel/UCFEsQDX6n_Xb7FtiLTq1sWQ/videos",
    "_blank"
  );
};

const handleFBClick = () => {
  window.open(
    "https://www.facebook.com/profile.php?id=61551130580674",
    "_blank"
  );
};

const handleLIClick = () => {
  window.open("https://www.linkedin.com/company/freethrow", "_blank");
};

// Not sure how to pass props into icon here, but it works for now
const CardIcon = ({ icon }) => <div>{icon}</div>;

const ImgMediaCard = () => {
  return (
    <Card>
      <CardContent>
        <ListItemIcon>
          <GroupsIcon />
        </ListItemIcon>
        <Typography
          variant="h5"
          component="h2"
          style={{ marginBottom: "-30px" }}
        >
          Partners
        </Typography>
      </CardContent>
      {/* <CardMedia
        component="img"
        alt="logos"
        height="10px"
        src={imageSource}
        style={{ paddingLeft: "20%", paddingRight: "20%" }}
      /> */}
      {/* <CardMedia>
        <img src={imageSource} style={style1} />
      </CardMedia> */}
      <CardContent>
        <Typography color="text.secondary">
          Our partnerships help people get access to the public benefits they
          need.
          <List component="ol">
            <ListItem component="li" disablePadding="true">
              <ListItemText primary="We work with social services organizations, community health organizations, food banks, and other nonprofits serving the needs of individuals and families who may be eligible for public benefits programs." />
            </ListItem>
            <ListItem component="li" disablePadding="true">
              <ListItemText primary="Interested in working with us?  Please email us at support@freethrow.ai." />
            </ListItem>
            {/* Add more ListItem components for additional items */}
          </List>
        </Typography>
      </CardContent>
    </Card>
  );
};

// Card component example
const CustomCard = ({ title, content, icon, pagelink }) => {
  return (
    <Card
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <CardActionArea component={Lnk} to={pagelink}>
        <CardContent>
          <ListItemIcon>
            <CardIcon icon={icon} />
            {/* <ApprovalIcon sx={{ color: "black" }} /> */}
          </ListItemIcon>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography color="text.secondary">{content}</Typography>

          {/* <CardActions>
          <Button component={Lnk} to={pagelink} color="primary">
            {actiontxt}
          </Button>
          {/* <Button size="small">{actiontxt}</Button> */}
          {/*</CardActions> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const BottomBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#322294", // Set the desired background color
        padding: "20px", // Set padding as needed

        bottom: 0,
        width: "100%",
      }}
    >
      <Typography variant="h7" color="white" fontSize={12}>
        *By providing my phone number, I understand that FreeThrow may send text
        messages as part of efforts to help me claim my benefits. FreeThrow will
        never charge for text messages. Carrier message and data rates may apply
        to these messages. Text “STOP” to stop receiving messages. See Terms &
        Conditions on the FAQs page.
      </Typography>

      <div style={{ padding: 10 }} />
      <IconButton
        onClick={handleYTClick}
        sx={{ color: "white" }}
        aria-label="Youtube"
      >
        <YouTubeIcon />
      </IconButton>
      <IconButton
        onClick={handleFBClick}
        sx={{ color: "white" }}
        aria-label="FaceBook"
      >
        <FacebookIcon />
      </IconButton>
      <IconButton
        onClick={handleLIClick}
        sx={{ color: "white" }}
        aria-label="LinkedIn"
      >
        <LinkedInIcon />
      </IconButton>
      <Copyright />
    </Box>
  );
};

const SubmitCard = ({ title, pagelink }) => {
  return (
    <Card>
      <CardActionArea component={Lnk} to={pagelink}>
        <CardContent>
          <ListItemIcon>
            <ApprovalIcon />
          </ListItemIcon>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography color="text.secondary">
            Easily apply to multiple public benefit programs at the same time
            using our portal.
            <List component="ol">
              <ListItem component="li" disablePadding="true">
                <ListItemText
                  primary="1. Consolidates the application process into one simple application for SNAP, Medicaid/CHIP, and TANF.
"
                />
              </ListItem>
              <ListItem component="li" disablePadding="true">
                <ListItemText
                  primary="2. Bypasses long phone hold times, long interview date scheduling delays,
          and clunky online state application portals"
                />
              </ListItem>
              <ListItem component="li" disablePadding="true">
                <ListItemText
                  primary="3. Provides a centralized
          record of applications."
                />
              </ListItem>
              {/* Add more ListItem components for additional items */}
            </List>
          </Typography>
          {/* <CardActions>
          <Button component={Lnk} to={pagelink} color="primary">
            {actiontxt}
          </Button>
          {/* <Button size="small">{actiontxt}</Button> */}
          {/*</CardActions> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const TopCard = ({ title, pagelink }) => {
  return (
    <Card>
      {/* <CardActionArea> */}
      <CardContent>
        {/* <ListItemIcon>
            <ApprovalIcon />
          </ListItemIcon> */}
        <Typography
          variant="h4"
          component="h2"
          sx={{ textAlign: "center" }}
          style={{ marginBottom: "-10px", marginTop: "10px" }}
        >
          {title}
        </Typography>
        <br />
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{ textAlign: "center" }}
        >
          Your personal benefits assistant
        </Typography>

        {/* <CardActions>
          <Button component={Lnk} to={pagelink} color="primary">
            {actiontxt}
          </Button>
          {/* <Button size="small">{actiontxt}</Button> */}
        {/*</CardActions> */}
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
};

// Row component containing three cards
const CardRow = () => {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="stretch">
      <Grid item xs={10}>
        <TopCard
          title="Welcome to FreeThrow.ai"
          actiontxt="Submit your information"
          pagelink="submit"
        />
      </Grid>
      <Grid item xs={10}>
        <SubmitCard
          title="Apply"
          actiontxt="Submit your information"
          pagelink="submit"
        />
      </Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <CustomCard
          title="Learn"
          content="Learn how billions of dollars worth of benefits are left unclaimed each year."
          pagelink="learn"
          icon={<LocalLibraryIcon />}
        />
      </Grid>
      <Grid item xs={5} style={{ display: "flex" }}>
        <CustomCard
          title="FAQs"
          content="Find out more about how FreeThrow can help you save money and time."
          pagelink="faqs"
          icon={<QuizIcon />}
        />
      </Grid>
      <Grid item xs={10}>
        <ImgMediaCard />
      </Grid>
    </Grid>
  );
};

const steps = ["Name", "Contact", "Submit"];
// TODO: get this .env file to work plz
// const siteKey = process.env.REACT_APP_SITE_KEY;
const siteKey = "6Le0RHgnAAAAALNgPOXlJT4bO50qrndPZCTeXjYp";

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme({
//   typography: {
//     allVariants: {
//       fontFamily: 'Raleway',
//     },
//   },
//   palette: {
//     mode: "dark",

//     primary: {
//       main: "#344954",
//     },
//     secondary: {
//       main: "#9c27b0",
//     },
//   },
// });

// TODO: expand on this to make sure entry validation works
// email address, check for @ and .com or whatever
// name etc, check that its not just a bunch of spaces or whatever (python strip?)
const formkeys = [
  "firstName",
  "lastName",
  "city",
  "stateUS",
  "address1",
  "address2",
  "emailAddress",
  "phoneNumber",
  "zipCode",
];

let initialform = {};
for (let key of formkeys) {
  initialform[key] = "";
}

let initialerrorstate = {};
for (let key of formkeys) {
  initialerrorstate[key + "_error"] = false;
}

const zipre = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");

// const emailre = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");
// const emailre = new RegExp("\b[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}\b");
const emailre = new RegExp("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$");

// TODO: split out into two state variables, one is step, other is form info
export default function FrontPage() {
  const captchaRef = React.useRef(null);
  const [appState, setActiveState] = React.useState({
    activeStep: 0,
    text: "initial text",
    msg: [],
    loading: false,
    disabledButton: false,
    termsModal: false,
    ...initialform,
    ...initialerrorstate,
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      {/* <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar variant='dense'>
          <Button component={Lnk} to="/" variant="text1"> Home </Button>
          <Button component={Lnk} to="/aboutus" variant="text1"> About Us </Button>
          <Button variant="text1"> FAQs </Button> */}

      {/* <Typography variant="h6" color="inherit" noWrap>
            Benefits Inc.
          </Typography> */}

      {/* </Toolbar>
      </AppBar> */}

      <div id="text_divs" style={{ paddingTop: 20, paddingBottom: 0 }}>
        <CardRow />
        {/* <Typography variant="h4" color="inherit" sx={{ textAlign: "center" }}>
          <br />
          Apply for public benefits today
          <br />
        </Typography> */}
      </div>

      <div style={{ margin: "32px 0" }}>
        <Divider />
      </div>

      <div style={{ margin: "32px 0" }}></div>

      {/* <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <ImgMediaCard />
        </Grid>
      </Grid> */}
      <BottomBar />
    </ThemeProvider>
  );
}
