import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { IMaskInput } from "react-imask";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";

// The def below is for formatting phone number
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default function PaymentForm({ state, fcn }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Contact Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="email"
            label="Email address"
            onChange={fcn("emailAddress")}
            error={state["emailAddress_error"]}
            fullWidth
            autoComplete="ctct-email"
            variant="standard"
            defaultValue={state.emailAddress}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            required
            id="phoneNumber"
            label="Phone number"
            onChange={fcn("phoneNumber")}
            fullWidth
            autoComplete="phone-number"
            variant="standard"
            
          /> */}
        <Grid item xs={12} md={6}>
          <FormControl variant="standard">
            <InputLabel htmlFor="formatted-text-mask-input">
              Phone number *
            </InputLabel>
            <Input
              value={state.phoneNumber}
              onChange={fcn("phoneNumber")}
              name="textmask"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom}
              error={state["phoneNumber_error"]}
            />
          </FormControl>

          <div style={{marginTop: '10rem'}}>
            {" "}
            {state.msg.map((key) => (
              <li> {key} </li>
            ))}{" "}
          </div>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Expiry date"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
