/** @format */

import "./App.css";
import React from "react";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import AboutUs from "./AboutUs";
import FAQs from "./FAQs";
import FrontPage from "./Main";
import Submit from "./Submit";
import Learn from "./Learn";

import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { FileDownload } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import QuizIcon from "@mui/icons-material/Quiz";
import InfoIcon from "@mui/icons-material/Info";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ApprovalIcon from "@mui/icons-material/Approval";
import Drawer from "@mui/material/Drawer";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { Amplify, API, graphqlOperation } from "aws-amplify";
import awsconfig from "./aws-exports";
import { fontGrid } from "@mui/material/styles/cssUtils";

Amplify.configure(awsconfig);

const TitleLink = () => {
  const styles = {
    link: {
      textDecoration: "none",
    },
  };
  return (
    <Box justifyContent="left">
      <ThemeProvider theme={logoTheme}>
        <Typography
          component="a"
          variant="h4"
          color="common.white"
          noWrap
          sx={styles.link}
          href="/"
        >
          FreeThrow
        </Typography>
      </ThemeProvider>
    </Box>
  );
};

export const defaultTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Raleway",
    },
  },
  palette: {
    mode: "light",
    // background: {
    //   default: "#e4f0e2"
    // },
    primary: {
      main: "#4831D4",
    },
    secondary: {
      main: "#9c27b0",
    },
  },
});

const logoTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Dosis",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#344954",
    },
    secondary: {
      main: "#9c27b0",
    },
  },
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// TODO: IDEAS FOR IMPROVING LAYOUT
// "apply for public benefits today" should be a title font
// Try out having a separation to the form section (make this slick a la rh or other sites that "swipe" for new content)
// Add maybe a link to application at top middle in appbar
// Maybe try putting logos in a bottom bar so that they are against a dark background (see oi site)
// Get inspiration from OI site for a better layout of the mission/vision
// i think you can also try putting the contact info at the bottom of every page
// Try putting
// In general spend time lookin at other things on rh/oi that are nice

export default function App() {
  // TODO: change this function to whatever you need to do api queries
  // async function fetchData() {
  //   const apiData = await API.graphql(graphqlOperation(listUntitledModels))
  //   console.log("function was used")
  //   console.log(apiData)
  //   return apiData
  // }
  // fetchData()
  const [open, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };
  return (
    <Router>
      <ScrollToTop />
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <AppBar
          position="absolute"
          color="default"
          style={{ background: "#4831D4" }}
          elevation={0}
          sx={{
            position: "fixed",
            borderBottom: (t) => `1px solid ${t.palette.divider}`,
          }}
        >
          <Toolbar variant="dense" sx={{ height: "65px" }}>
            {/* <Button component={Lnk} to="/" variant="text" > Text </Button> */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
            >
              {/* <Typography variant="h6" color="inherit" noWrap>
              Freethrow Inc.
                </Typography> */}
              <Box justifyContent="left">
                <ThemeProvider theme={logoTheme}>
                  <TitleLink />
                </ThemeProvider>
              </Box>
              <Box justifyContent="right">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                  sx={{ mr: 2, display: { xs: "block", sm: "block" } }}
                >
                  <MenuIcon style={{ fill: "white" }} />
                </IconButton>

                <Drawer
                  anchor="right" //from which side the drawer slides in
                  variant="temporary" //if and how easily the drawer can be closed
                  open={open} //if open is true, drawer is shown
                  // onClose={toggleDrawer(false)} //function that is called when the drawer should close
                  // onOpen={toggleDrawer(true)} //function that is called when the drawer should open
                >
                  <Box
                    sx={{
                      p: 2,
                      height: 1,
                      backgroundColor: "primary.main",
                    }}
                  >
                    {/* 
                  when clicking the icon it calls the function toggleDrawer 
                  and closes the drawer by setting the variable open to false
                  */}
                    <IconButton sx={{ mb: 2 }}>
                      <CloseIcon
                        onClick={toggleDrawer(false)}
                        sx={{ color: "white" }}
                      />
                    </IconButton>

                    <Box sx={{ mb: 2, mr: 10 }}>
                      <ListItemButton
                        component={Link}
                        to="/"
                        onClick={toggleDrawer(false)}
                      >
                        <ListItemIcon>
                          <HomeIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Home" sx={{ color: "white" }} />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to="/submit"
                        onClick={toggleDrawer(false)}
                      >
                        <ListItemIcon>
                          <ApprovalIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Apply" sx={{ color: "white" }} />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to="/learn"
                        onClick={toggleDrawer(false)}
                      >
                        <ListItemIcon>
                          <LocalLibraryIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="Learn" sx={{ color: "white" }} />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to="/aboutus"
                        onClick={toggleDrawer(false)}
                      >
                        <ListItemIcon>
                          <InfoIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary="About FreeThrow"
                          sx={{ color: "white" }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to="/faqs"
                        onClick={toggleDrawer(false)}
                      >
                        <ListItemIcon>
                          <QuizIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary="FAQs" sx={{ color: "white" }} />
                      </ListItemButton>
                    </Box>

                    {/* <Button component={Link} to="/" variant="text1">
                      <Typography variant="h7" color="common.white">
                        Home
                      </Typography>
                    </Button>
                    <Button component={Link} to="/aboutus" variant="text1">
                      <Typography variant="h7" color="common.white">
                        About
                      </Typography>
                    </Button>
                    <Button component={Link} to="/faqs" variant="text1">
                      <Typography variant="h7" color="common.white">
                        FAQs
                      </Typography>
                    </Button> */}
                  </Box>
                </Drawer>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <div style={{ marginTop: "48px" }}>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Your Money</Link>
            </li>
            <li>
              <Link to="/form">Form</Link>
            </li>
          </ul>
        </nav> */}

        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" element={<FrontPage />}></Route>
          <Route path="/aboutus" element={<AboutUs />}></Route>
          <Route path="/submit" element={<Submit />}></Route>
          <Route path="/faqs" element={<FAQs />}></Route>
          <Route path="/learn" element={<Learn />}></Route>
        </Routes>
      </div>
    </Router>
  );
}
