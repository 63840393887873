import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

const information = [
  {
    name: 'Name',
    desc: ['firstName', 'lastName'],
  },
  {
    name: 'Address',
    desc: ['address1', 'address2', 'city', 'stateUS', 'zipCode'],
  },
  {
    name: 'Email',
    desc: ['emailAddress'],
  },
  {
    name: 'Telephone Number',
    desc: ['phoneNumber'],
  },
  
  
  
];

const addresses = ['1 MUI Drive', 'Reactville', 'Anytown', '99999', 'USA'];
const payments = [
  { name: 'Card type', detail: 'Visa' },
  { name: 'Card holder', detail: 'Mr John Smith' },
  { name: 'Card number', detail: 'xxxx-xxxx-xxxx-1234' },
  { name: 'Expiry date', detail: '04/2024' },
];

function combineText(stateKeyArray, state) {
  let txt = ""
  for (let key of stateKeyArray) {
    // console.log(key)
    txt += " " + state[key]
  }
  return txt
}

// combineText(info.desc, state)

export default function Review({state, fcn}) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Confirm and Submit
      </Typography>
      <List disablePadding>
        {information.map((info) => (
          <ListItem key={info.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={info.name} secondary={combineText(info.desc, state)} />
            {/* <Typography variant="body2">{product.price}</Typography> */}
          </ListItem>
        ))}

        {/* <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            $34.06
          </Typography>
        </ListItem> */}
      </List>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping
          </Typography>
          <Typography gutterBottom>John Smith</Typography>
          <Typography gutterBottom>{addresses.join(', ')}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment details
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}
