/** @format */

import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { BottomBar } from "./Main.js";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./App";
import { PieChart } from "@mui/x-charts/PieChart";
import Box from "@mui/material/Box";
import { flexbox } from "@mui/system";

import {
  Paper,
  Card,
  CardMedia,
  CardContent,
  Grid,
  CardActions,
  CardActionArea,
  Divider,
} from "@mui/material";

import { terms, privacy, EULA, use, disclaimer } from "./strings.js";

import { BarChart } from "@mui/x-charts/BarChart";

const seriesA = {
  data: [87.8, 70.2, 72.3],
  label: "Account required",
};
const seriesB = {
  data: [12.2, 29.8, 27.7],
  label: "Account not required",
};

function BasicStacking() {
  return (
    <BarChart
      // width={600}
      height={300}
      colors={colorpalette}
      slotProps={{
        legend: {
          position: { vertical: "top", horizontal: "middle" },
          direction: "row",
          labelStyle: {
            fontSize: 10,
          },
        },
      }}
      xAxis={[{ scaleType: "band", data: ["Medicaid", "SNAP", "TANF"] }]}
      series={[
        {
          ...seriesA,
          stack: "total",
          valueFormatter: (x) => x.toString().concat("%"),
        },
        {
          ...seriesB,
          stack: "total",
          valueFormatter: (x) => x.toString().concat("%"),
        },
      ]}
    />
  );
}

const data = [
  {
    id: 0,
    value: 74,
    label: "Social Security \n (Old-age and Survivors)",
  },
  { id: 1, value: 39, label: "Medicaid" },
  { id: 2, value: 25, label: "Medicare" },
  { id: 3, value: 22, label: "SSI" },
  { id: 4, value: 17, label: "EITC" },
  { id: 5, value: 13, label: "SNAP" },
];

const currencyFormatter = (val) =>
  "$".concat(val.value.toString()).concat(" Billion");

const colorpalette = [
  "#4731d4",
  "#bed431",
  "#316dd4",
  "#9831d4",
  "#d431be",
  "#d44731",
];

function PieActiveArc() {
  return (
    <PieChart
      series={[
        {
          data,
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          valueFormatter: currencyFormatter,
        },
      ]}
      height={300}
      // width={250}
      slotProps={{
        legend: {
          position: { vertical: "top", horizontal: "middle" },
          padding: 0,
          direction: "row",
          labelStyle: {
            fontSize: 10,
          },
        },
      }}
      colors={colorpalette}
      margin={{ top: 60 }}
    />
  );
}

function Learn() {
  const [State, setActiveState] = React.useState({
    showterms: false,
    content: "",
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div id="text_divs" style={{ padding: 20, paddingTop: 40 }}>
        <Grid
          container
          spacing={2}
          paddingBottom={5}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" color="inherit">
              Unclaimed Benefits
            </Typography>{" "}
            <Typography variant="body1" color="inherit">
              How many billions of dollars go unclaimed every year?
              Approximately $200 billion, equivalent to $600 for every person in
              the United States.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PieActiveArc />
            <Typography variant="caption" color="inherit">
              Source: Barnett, A. and N. Wilson. 2016. "US Federal Government
              Expenditures, Shortfalls in Beneficiaries’ Take-up, and Barriers
              to Take-up/Opportunities for Benefits Integration".
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" color="inherit">
              Application Frictions
            </Typography>{" "}
            <Typography variant="body1" color="inherit">
              Applying on state websites can be confusing and burdensome. One
              measure of how difficult it is to apply is whether your state
              requires that you create an account to apply. We researched this
              process for all 50 states and found that most states make applying
              for public benefits much more difficult than it needs to be.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BasicStacking />
            <Typography variant="caption" color="inherit">
              Source: Source: FreeThrow.ai study of User Interface (UI) for all
              states that provide online Medicaid, SNAP and TANF application
              processes. Notes: not all states offer online application
              processes for these benefits.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <BottomBar />
    </ThemeProvider>
  );
}

export default Learn;
