/** @format */

import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { BottomBar } from "./Main.js";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./App";

import { terms, privacy, EULA, use, disclaimer } from "./strings.js";

function FAQs() {
  const [State, setActiveState] = React.useState({
    showterms: false,
    content: "",
  });

  const enabledialog = () => {
    // Just enable the dialog to be shown
    let dialog = { showterms: true };
    setActiveState((State) => ({
      ...State,
      ...dialog,
    }));
  };

  const disabledialog = () => {
    // Just disable the button to be clicked
    let dialog = { showterms: false };
    setActiveState((State) => ({
      ...State,
      ...dialog,
    }));
  };

  const setContent = (content) => {
    if (content == "terms") {
      let x = { content: terms, title: "Terms of Service" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
    if (content == "privacy") {
      let x = { content: privacy, title: "Privacy Policy" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
    if (content == "EULA") {
      let x = { content: EULA, title: "END-USER LICENSE AGREEMENT (EULA)" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
    if (content == "use") {
      let x = { content: use, title: "ACCEPTABLE USE POLICY" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
    if (content == "disclaimer") {
      let x = { content: disclaimer, title: "DISCLAIMER" };
      setActiveState((State) => ({
        ...State,
        ...x,
      }));
    }
  };

  const enabledialogv2 = (content) => {
    // Just enable the dialog to be shown
    setContent(content);
    let dialog = { showterms: true };
    setActiveState((State) => ({
      ...State,
      ...dialog,
    }));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <div id="text_divs" style={{ padding: 20, paddingTop: 40 }}>
        <Typography variant="h6" color="inherit">
          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              {" "}
              Can I really receive money by answering the 4 questions on the
              FreeThrow web app?
            </li>
            <br />
            <Typography variant="body1" color="inherit">
              Yes, our team has identified $200 billion in unclaimed benefits
              each year in the United States. This translates into $606 for each
              of the approximately 330 million people in this country.
            </Typography>
          </div>
          <br />
          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              {" "}
              How much will I receive?
            </li>
            <br />
            <Typography variant="body1" color="inherit">
              If you qualify, you may receive more than $30,000 a year.
              Eligibility depends on factors such as income and assets.
            </Typography>
          </div>
          <br />
          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              Do I qualify for Federal/state government benefits?
            </li>
            <br />
            <Typography variant="body1" color="inherit">
              Did you earn less than $1,732 last month? Even if you earned more
              than that you might qualify if you have dependents, are pregnant,
              or live with a disability. If you answered “yes” to any of these
              questions, you likely are eligible for Federal/state government
              benefits. Even if you are not sure you will qualify, it only takes
              2 minutes to initiate applications via our website.
            </Typography>
          </div>
          <br />
          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              Why should I use the FreeThrow web app to initiate my
              applications?{" "}
            </li>
            <br />
            <Typography variant="body1" color="inherit">
              Our process is easy and secure. Bypass long phone hold times and
              have a state agent call you to finalize your application in
              real-time over the phone. We protect your data using industry best
              practices and will not sell it to any 3rd parties.
            </Typography>
          </div>
          <br />
          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              What programs does FreeThrow support?{" "}
            </li>
            <br />
            <Typography variant="body1" color="inherit">
              We support SNAP, Medicaid, and TANF in all 50 states and DC. In
              many states these programs may be branded using names like
              CalFresh, YourTexasBenefits, and LINK. In some states we also
              support additional programs.
            </Typography>
          </div>
          <br />

          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              What will happen after I answer these 4 questions on the FreeThrow
              web app?{" "}
            </li>
            <br />
            <Typography variant="body1" color="inherit">
              We will initiate your applications for multiple public benefits
              programs. Representative(s) from the relevant state office(s)
              should call you in approximately 10 days to confirm basic
              information about your application including your income, living
              arrangements, etc. You will begin receiving your benefits if you
              do indeed qualify for one or more benefits programs. We will work
              to keep you informed about key updates.
            </Typography>
          </div>
          <br />
          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              How do you keep my data secure?{" "}
            </li>
            <br />
            <Typography variant="body1" color="inherit">
              We will protect your information using best industry practices,
              and will not sell it to any 3rd parties.
            </Typography>
            <br />
            <Dialog
              open={State.showterms}
              onClose={disabledialog}
              aria-labelledby="dialog-dialog-title"
              aria-describedby="dialog-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">
                {" "}
                {State.title}{" "}
              </DialogTitle>
              <DialogContent>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {State.content.split("<br/>").join("\n")}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={disabledialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <li style={{ listStyleType: "none", fontWeight: "bold" }}>
              What are the terms of service, etc?{" "}
            </li>
            <br />
            <Typography variant="body1" color="inherit">
              By using our service you agree to the following documents:
              <Link
                style={{ marginLeft: ".2rem" }}
                component="button"
                onClick={() => {
                  enabledialogv2("terms");
                }}
              >
                Terms of Service
              </Link>
              ,
              <Link
                style={{ marginLeft: ".2rem" }}
                component="button"
                onClick={() => {
                  enabledialogv2("privacy");
                }}
              >
                Privacy Policy
              </Link>
              ,
              <Link
                style={{ marginLeft: ".2rem" }}
                component="button"
                onClick={() => {
                  enabledialogv2("EULA");
                }}
              >
                EULA
              </Link>
              ,
              <Link
                style={{ marginLeft: ".2rem" }}
                component="button"
                onClick={() => {
                  enabledialogv2("use");
                }}
              >
                Acceptable Use Policy
              </Link>
              ,
              <Link
                style={{ marginLeft: ".2rem" }}
                component="button"
                onClick={() => {
                  enabledialogv2("disclaimer");
                }}
              >
                Disclaimer
              </Link>
              .
            </Typography>
            <br />
            <Dialog
              open={State.showterms}
              onClose={disabledialog}
              aria-labelledby="dialog-dialog-title"
              aria-describedby="dialog-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">
                {" "}
                {State.title}{" "}
              </DialogTitle>
              <DialogContent>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {State.content.split("<br/>").join("\n")}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={disabledialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Typography>
      </div>

      <BottomBar />
    </ThemeProvider>
  );
}

export default FAQs;
