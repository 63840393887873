/** @format */

import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { stateabbrs } from "./USstates";
import MenuItem from "@mui/material/MenuItem";

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export default function AddressForm({ state, fcn }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Name & Address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            onChange={fcn("firstName")}
            error={state["firstName_error"]}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            defaultValue={state.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            onChange={fcn("lastName")}
            error={state["lastName_error"]}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            defaultValue={state.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            onChange={fcn("address1")}
            error={state["address1_error"]}
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            defaultValue={state.address1}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            onChange={fcn("address2")}
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
            defaultValue={state.address2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            onChange={fcn("city")}
            error={state["city_error"]}
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            defaultValue={state.city}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            id="state"
            name="state"
            label="State"
            onChange={fcn("stateUS")}
            error={state["stateUS_error"]}
            fullWidth
            variant="standard"
            defaultValue={state.stateUS}
          >
            {stateabbrs.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            onChange={fcn("zipCode")}
            error={state["zipCode_error"]}
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            defaultValue={state.zipCode}
            inputProps={{ maxLength: 10 }}
          />
          <div>
            {" "}
            {state.msg.map((key) => (
              <li> {key} </li>
            ))}{" "}
          </div>
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            variant="standard"
          />
        </Grid> */}
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
