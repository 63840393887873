/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { ListItem } from "@mui/material";
import { BottomBar } from "./Main.js";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./App";

const emailAddress = "support@freethrow.ai";

function AboutUs() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div id="text_divs" style={{ padding: 20, height: 800 }}>
        <h2> MISSION </h2>
        To benefit you.
        <h2> VISION </h2>
        An America where everyone receives the benefits to which they have a
        legal right.
        <h2>CORE VALUES </h2>
        Respect, privacy, security, financial success.
        <br />
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <h4> Contact Us </h4>
          FreeThrow, LLC <br /> 13359 N Hwy 183 <br /> STE 406-306-63516 <br />
          Austin, TX, 78750 ‪ <br />
          (737) 253-8510 <br />{" "}
          <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
        </div>
      </div>
      <BottomBar />
    </ThemeProvider>
  );
}

export default AboutUs;
