/**
 * eslint-disable
 *
 * @format
 */

// this is an auto generated file. This will be overwritten

export const validateAndPost = /* GraphQL */ `
  mutation ValidateAndPost(
    $firstname: String
    $lastname: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $phonenumber: String
    $email: String
    $captchakey: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
    $_version: Int
    $_deleted: Boolean
    $_lastChangedAt: AWSTimestamp
  ) {
    validateAndPost(
      firstname: $firstname
      lastname: $lastname
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      phonenumber: $phonenumber
      email: $email
      captchakey: $captchakey
      createdAt: $createdAt
      updatedAt: $updatedAt
      _version: $_version
      _deleted: $_deleted
      _lastChangedAt: $_lastChangedAt
    ) {
      statusCode
      message
      __typename
    }
  }
`;
